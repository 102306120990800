.App {
  margin-top: 15px;
  z-index: 100;
}

.App .navbar-brand {
  font-weight: bold;
}

.form>select {
  padding-left: .25rem;
  padding-right: 1rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  border-radius: 0.5rem;
}
.form>select:focus {
  background-color: antiquewhite;
  border-color: black;
}
.form>select:hover {
  background-color: aliceblue;
}

.add-button {
  border: 1px solid black; 
  background-color: rgba(0,0,0,0); 
  color: black; 
  padding: 5px;
}

.map-icon-label i {
  font-size: 24px;
  color: #FFFFFF;
  line-height: 55px;
  text-align: center;
  white-space: nowrap;
}
