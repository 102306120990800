body {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 0.813rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Nanum Gothic";
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
   font-family: "Nanum Gothic";
   font-weight: 700;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}

.titlesubject {
  font-size: 18px;
  font-weight: bold;
}

.btn {
  padding: 0.375rem 0.75rem;
  border: 1px solid teal;
  border-radius: 0.25rem;
  font-size: 0.813rem;
  line-height: 1.5;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.nav-link.active {
  color: #fff !important;
  background: black !important;
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/NanumGothicLight.woff2") format('woff2'),
  url("./fonts/NanumGothicLight.woff") format('woff'),
  url("./fonts/NanumGothicLight.otf") format('otf')
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/NanumGothic.woff2") format('woff2'),
  url("./fonts/NanumGothic.woff") format('woff'),
  url("./fonts/NanumGothic.otf") format('otf')
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/NanumGothicBold.woff2") format('woff2'),
  url("./fonts/NanumGothicBold.woff") format('woff'),
  url("./fonts/NanumGothicBold.otf") format('truetype')
}